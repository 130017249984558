import {Injectable} from '@angular/core';
import {StorageService} from "../storage/storage.service";
import {Observable} from "rxjs";
import {Session} from "../../models/session.model";

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private _session: Session | null = null;

    get session(): Session | null {
        return this._session;
    }

    constructor(private storageSrv: StorageService) {

    }

    public loadSession() {
        const session = this.storageSrv.get("session")

        if (session) {
            this._session = Session.factory(session)
        } else {
            this._session = null
        }
    }
}
