<div class="container" [ngClass]="styles">
    <div class="title-container" *ngIf="title">
        <h2>{{title}}</h2>
    </div>

    <div class="table-container">
        <table mat-table [dataSource]="data">

            <ng-container
                *ngFor="let column of columnList"
                [matColumnDef]="column.key"
            >
                <th mat-header-cell *matHeaderCellDef>{{column.title}}</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container
                        *ngTemplateOutlet="contentCell; context:{column, element}"
                    ></ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnList"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnList;"></tr>

        </table>

        <mat-paginator
            [pageSizeOptions]="pageSize"
            [length]="paginator.totalDocs"
            [pageSize]="paginator.limit"
            (page)="changePaginator($event)"
        ></mat-paginator>
    </div>
</div>

<ng-template #contentCell let-element="element" let-column="column">

    <ng-container *ngIf="column.type === 'text'">
        <span *ngIf="column.formatter">{{column.formatter(element)}}</span>
        <span *ngIf="!column.formatter">{{column.getValueOf(element)}}</span>
    </ng-container>

    <ng-container *ngIf="column.type === 'button'">
        <button
            class="fy-button"
            [ngClass]="column.style"
            *ngIf="column.cb"
            (click)="column.cb(element)"
        >
            {{column.formatter(element)}}
        </button>
    </ng-container>

</ng-template>
