import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {
    }


    public set(key: string, value: any): void {
        const v = JSON.stringify(value)
        localStorage.setItem(key, v)
    }

    public get(key: string): any {
        const value = localStorage.getItem(key)
        if (value) {
            return JSON.parse(value)
        } else {
            return null
        }
    }

    public remove(key: string): void {
        localStorage.removeItem(key)
    }

    public clear(): void {
        localStorage.clear()
    }

}
