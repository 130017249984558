<div class="page height-100vh fy-grid justify-center align-center">
    <div class="sign-in">
        <div class="logo text-center margin-bottom-30px">
            <img src="/assets/logo-jaak.png" alt="JAAK" width="250">
        </div>

        <div class="fy-card width-400px" [formGroup]="form">
            <div class="fy-title text-center margin-bottom-40px">Bienvenido al etiquetador</div>

            <div class="fy-field margin-bottom-30px">
                <div class="label">Correo electronico</div>
                <input type="text" class="control" placeholder="pepe@jaak.ai" formControlName="email">
            </div>

            <div class="fy-field margin-bottom-30px">
                <div class="label">Contraseña</div>
                <input type="password" class="control" placeholder="**********" formControlName="password">
            </div>

            <button class="fy-button primary width-100%" (click)="signIn()">Iniciar sesión</button>
        </div>
    </div>
</div>
