import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SignInComponent} from "./pages/sign-in/sign-in.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {CompareComponent} from "./pages/compare/compare.component";
import {AuthGuard} from "./guards/auth.guard";

const routes: Routes = [
    {
        path: "sign-in",
        component: SignInComponent,
        canActivate: [ AuthGuard ],
    },
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [ AuthGuard ],
        data: {
            breadcrumb: {
                info: {
                    icon: "home"
                }
            }
        },
        children: [
            {
                path: "compare",
                component: CompareComponent,
                data: {
                    breadcrumb: {
                        label: 'Comparaciones'
                    }
                },
            }
        ]
    },
    {
        path: "**",
        redirectTo: "sign-in"
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class WebappRoutingModule { }
