import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import {PageEvent} from "@angular/material/paginator";
import {ColumnTable} from "./columns/colum-table";
import {Paginator} from "../../tools/paginator";

@Component({
    selector: "fy-table",
    templateUrl: "./fy-table.component.html",
    styleUrls: ["./fy-table.component.sass"],
    encapsulation: ViewEncapsulation.None,
})
export class FyTableComponent implements OnInit {
    data$: Array<any>;
    columnList$: Array<ColumnTable> = [];
    displayedColumnList: Array<string> = [];

    @Input() title: string;
    @Input() paginator: Paginator
    @Input() pageSize: Array<number> = [5, 10, 20];
    @Input() styles: string

    @Input()
    get data(): Array<any> {
        return this.data$;
    }

    set data(value: Array<any>) {
        this.data$ = value;
    }

    @Input()
    get columnList(): Array<ColumnTable> {
        return this.columnList$;
    }

    set columnList(value: Array<ColumnTable>) {
        this.columnList$ = value
        this.displayedColumnList = value.map((colum) => colum.key);
    }

    @Output() onChangePage: EventEmitter<number> = new EventEmitter<number>();

    ngOnInit() {
    }

    public changePaginator(event: PageEvent): void {
        const previousPageIndex = event.previousPageIndex || 0
        const currentPageIndex = event.pageIndex

        if (currentPageIndex < previousPageIndex) {
            this.onChangePage.emit(--this.paginator.page)
        } else if (currentPageIndex >  previousPageIndex) {
            this.onChangePage.emit(++this.paginator.page)
        }
    }
}
