import {Component, OnInit} from '@angular/core';
import {AppService} from "../../services/app/app.service";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

    constructor(public appSrv: AppService) {
    }

    ngOnInit(): void {
    }

}
