import {Injectable} from '@angular/core';
import {SessionService} from "../session/session.service";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private loading: BehaviorSubject<boolean>

    get isLoading(): Observable<boolean> {
        return this.loading.asObservable()
    }

    constructor() {
        this.loading = new BehaviorSubject<boolean>(false)
    }

    static initializer(sessionSrv: SessionService) {
        return (): Promise<void> => new Promise((resolve, reject) => {
            sessionSrv.loadSession()
            resolve();
        })
    }

    public setLoading(loading: boolean) {
        this.loading.next(loading)
    }
}
