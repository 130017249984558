<mat-sidenav-container class="sidenav-container">
    <mat-sidenav
        #sidenav
        mode="side"
        class="sidenav"
    >
        <img class="logo" src="/assets/logo-jaak.png" alt="JAAK">
        <div class="line"></div>
        <div class="item-list">
            <div
                *ngFor="let item of menuList"
                class="fy-item margin-bottom-5px"
                [routerLink]="item.path"
                [routerLinkActive]="'active'"
            >
                <div class="icon icon-left" *ngIf="item.icon">
                    <mat-icon [fontIcon]="item.icon"></mat-icon>
                </div>
                <span>{{item.label}}</span>
            </div>
            <div class="fy-item margin-bottom-5px" (click)="signOut()">
                <div class="icon icon-left">
                    <mat-icon fontIcon="close"></mat-icon>
                </div>
                <span>Cerrar sesión</span>
            </div>

        </div>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
        <div class="header-page">
            <div class="menu-toggle">
                <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
            </div>

            <div class="breadcrumb">
                <xng-breadcrumb>
                    <ng-container
                        *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first"
                    >
                        <mat-icon *ngIf="info && info.icon">{{ info.icon }}</mat-icon>
                        <ng-container *ngIf="!first">{{ breadcrumb }}</ng-container>
                    </ng-container>
                </xng-breadcrumb>
            </div>
        </div>

        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
