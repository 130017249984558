import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Tagger} from "../../models/tagger.model";
import {Images} from "../../models/images.model";
import {map} from "rxjs/operators";
import {Config} from "../../classes/config";
import {Counter} from "../../models/counter.model";

@Injectable({
    providedIn: 'root'
})
export class ImagesService {

    constructor(
        private http: HttpClient,
    ) {
    }

    public getImages(): Observable<any> {
        return this.http.get(`${Config.api}/pair`)
            .pipe(
                map((response: any) => Images.factory(response))
            )
    }

    public tagger(id: string, tagger: Tagger): Observable<Counter> {
        return this.http.put(`${Config.api}/pair/${id}`, tagger)
            .pipe(
                map((response: any) => Counter.factory(response))
            )
    }
}
