import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {Credentials} from "../../models/credentials.model";
import {firstValueFrom} from "rxjs";
import {StorageService} from "../../services/storage/storage.service";
import {ToolsService} from "../../services/tools/tools.service";
import {SessionService} from "../../services/session/session.service";
import {AppService} from "../../services/app/app.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.sass']
})
export class SignInComponent implements OnInit {
    form: FormGroup

    constructor(
        private fb: FormBuilder,
        private authenticationSrv: AuthenticationService,
        private storageSrv: StorageService,
        private sessionSrv: SessionService,
        private toolsSrv: ToolsService,
        private appSrv: AppService,
        private snackBar: MatSnackBar
    ) {
    }

    public ngOnInit() {
        this.setupVars()
    }

    private setupVars() {
        this.form = this.fb.group({
            email: [""],
            password: [""]
        })
    }

    public async signIn() {
        try {
            this.appSrv.setLoading(true)
            const credentials = Credentials.factory(this.form.value)
            const session = await firstValueFrom(this.authenticationSrv.signIn(credentials))
            this.appSrv.setLoading(false)

            this.storageSrv.clear()
            this.storageSrv.set("session", session)
            this.sessionSrv.loadSession()
            await this.toolsSrv.goToCompare()
        } catch (e: any) {
            const message = e.error?.message || e.message || "Ocurrio un error vuelva a intentarlo"

            this.appSrv.setLoading(false)
            this.snackBar.open(message)
        }

    }
}
