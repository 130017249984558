import {plainToInstance} from "class-transformer";

export class Credentials {
    email: string;
    password: string;

    static factory(payload: any): Credentials {
        return plainToInstance(Credentials, payload)
    }
}
