import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from "./components/app/app.component";
import {WebappRoutingModule} from "./webapp-routing.module";
import {DabosfyModule} from "../dabosfy/dabosfy.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {SignInComponent} from "./pages/sign-in/sign-in.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {CompareComponent} from "./pages/compare/compare.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {BreadcrumbModule} from "xng-breadcrumb";
import {NgxEchartsModule} from "ngx-echarts";
import {ReactiveFormsModule} from "@angular/forms";
import {AppService} from "./services/app/app.service";
import {SessionService} from "./services/session/session.service";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AuthenticationInterceptor} from "./interceptor/authentication.interceptor";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatChipsModule} from "@angular/material/chips";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";


@NgModule({
    declarations: [
        AppComponent,
        SignInComponent,
        DashboardComponent,
        CompareComponent,
    ],
    imports: [
        BrowserModule,
        WebappRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        DabosfyModule,
        MatSidenavModule,
        MatIconModule,
        BreadcrumbModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        ReactiveFormsModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatChipsModule
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: AppService.initializer,
            deps: [SessionService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class WebappModule {
}
