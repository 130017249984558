import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "fyCast",
    pure: true,
})
export class FyCastPipe implements PipeTransform {
    transform<T>(value: any, clss: new (...args: any[]) => T): T {
        return value as T;
    }
}
