import {plainToInstance} from "class-transformer";

export class Images {
    id: string
    image1: string
    image2: string
    requestId: string

    static factory(payload: any): Images {
        return plainToInstance(Images, payload)
    }
}
