import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FyTableComponent} from "./components/fy-table/fy-table.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatIconModule} from "@angular/material/icon";
import {FyCastPipe} from "./pipes/fyCast.pipe";
import {MatSortModule} from "@angular/material/sort";


@NgModule({
    declarations: [
        FyTableComponent,
        FyCastPipe
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatIconModule,
        MatSortModule
    ],
    exports: [
        FontAwesomeModule,
        FyTableComponent
    ]
})

export class DabosfyModule {
}
