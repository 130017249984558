import {plainToInstance} from "class-transformer";

export class Counter {
    totalEvaluation: number
    totalEvaluationRemaining: number

    static factory(payload: any): Counter {
        return plainToInstance(Counter, payload)
    }
}
