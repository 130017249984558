import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent} from '@angular/common/http';
import {SessionService} from '../services/session/session.service';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(private sessionSrv: SessionService) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const session = this.sessionSrv.session;


        let request = req;

        if (session) {
            request = req.clone({
                setHeaders: {
                    authorization: `Bearer ${session?.accessToken}`
                }
            })
        }

        return next.handle(request)
    }

}
