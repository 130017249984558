import { Injectable } from '@angular/core';
import { Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(private router: Router) { }

    goToLogin(): Promise<boolean> {
        return this.router.navigate(['/sign-in'])
    }

    goToCompare(): Promise<boolean> {
        return this.router.navigate(['/dashboard/compare'])
    }
}
