import {plainToInstance} from "class-transformer";

export class Paginator {
    totalDocs: number;
    totalPages: number;
    limit: number;
    page: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;

    static deserialize(payload: any): Paginator {
        return plainToInstance(Paginator, payload)
    }
}
