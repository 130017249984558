import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SessionService} from "../services/session/session.service";
import {ToolsService} from "../services/tools/tools.service";


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    public publicPathUrl = ['/sign-in'];

    constructor(
        private sessionSrv: SessionService,
        private toolsSrv: ToolsService
    ) {
    }

    canActivate(
        routeSnapshot: ActivatedRouteSnapshot,
        routeState: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const path = routeState.url;
        const session = this.sessionSrv.session;

        if (session) {
            if (this.isPublicPath(path)) {
                this.toolsSrv.goToCompare().then()
                return false;
            } else {
                return true;
            }
        } else {
            if (this.isPublicPath(path)) {
                return true;
            } else {
                this.toolsSrv.goToLogin().then();
                return false;
            }
        }


    }

    private isPublicPath(path: string): boolean {
        return !!this.publicPathUrl.find((p) => path.startsWith(p));
    }

}
