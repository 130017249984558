import {plainToInstance} from "class-transformer";
import {UserSession} from "./user-session.model";

export class Session {
    accessToken: string;
    user: UserSession;

    static factory(payload: any): Session {
        return plainToInstance(Session, payload)
    }
}
