<div class="page-container">
    <div class="counter-content" *ngIf="counter">
        <span>{{counter.totalEvaluation - counter.totalEvaluationRemaining }} / {{counter.totalEvaluation}}</span>
    </div>
    <div class="page-content" *ngIf="images">
        <div class="title-content">
            <div class="fy-title">¿Es la misma persona?</div>
        </div>
        <div class="chip-list">
            <span class="chip">Request Id: {{images.requestId}}</span>
        </div>
        <div class="images-content" [formGroup]="form" >
            <div *ngIf="images.image1" class="card-content fy-card margin-right-10px" formGroupName="image1">
                <div class="image-content">
                    <img [src]="'data:image/jpg;base64,'+images.image1" alt="image-1">
                </div>
                <div class="label-content">
                    <mat-checkbox color="primary" formControlName="blur">Borroso</mat-checkbox>
                    <mat-checkbox color="primary" formControlName="dark">Oscuro</mat-checkbox>
                </div>
            </div>
            <div *ngIf="images.image2" class="card-content fy-card margin-left-10px" formGroupName="image2">
                <div class="image-content">
                    <img [src]="'data:image/jpg;base64,'+images.image2" alt="image-2">
                </div>
                <div class="label-content">
                    <mat-checkbox color="primary" formControlName="blur">Borroso</mat-checkbox>
                    <mat-checkbox color="primary" formControlName="dark">Oscuro</mat-checkbox>
                </div>
            </div>
        </div>
        <div class="actions-content">
            <button
                class="fy-button success button"
                [disabled]="appSrv.isLoading | async"
                (click)="taggerImages(IS_SAME_PERSON.YES)"
            >Si
            </button>
            <button
                class="fy-button danger button"
                [disabled]="appSrv.isLoading | async"
                (click)="taggerImages(IS_SAME_PERSON.NO)"
            >No
            </button>
            <button
                class="fy-button warning button"
                [disabled]="appSrv.isLoading | async"
                (click)="taggerImages(IS_SAME_PERSON.UNKNOWN)"
            >No se
            </button>
        </div>
    </div>
</div>
