import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Tagger} from "../../models/tagger.model";
import {ImagesService} from "../../services/images/images.service";
import {firstValueFrom} from "rxjs";
import {Images} from "../../models/images.model";
import {AppService} from "../../services/app/app.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IsSamePerson} from "../../enums/is-same-person.enum";
import {Counter} from "../../models/counter.model";

@Component({
    selector: 'app-compare',
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.sass']
})
export class CompareComponent implements OnInit {
    form: FormGroup
    images: Images
    counter: Counter
    IS_SAME_PERSON = IsSamePerson

    constructor(
        public appSrv: AppService,
        private fb: FormBuilder,
        private imagesSrv: ImagesService,
        private snackBar: MatSnackBar
    ) {
    }

    public ngOnInit(): void {
        this.setupVars()
        this.loadPairImages().then()
    }

    private setupVars(): void {
        this.form = this.fb.group({
            image1: this.fb.group({
                blur: [false],
                dark: [false],
            }),
            image2: this.fb.group({
                blur: [false],
                dark: [false],
            })
        })
    }

    private async loadPairImages(): Promise<void> {
        this.appSrv.setLoading(true)
        await this.loadImages()
        this.appSrv.setLoading(false)
    }

    private async loadImages(): Promise<void> {
        this.images = await firstValueFrom(this.imagesSrv.getImages())
    }

    public async taggerImages(isSame: IsSamePerson): Promise<void> {
        try {
            const tagger = Tagger.factory(
                {
                    requestId: this.images.requestId,
                    evaluation: {
                        isSamePerson: isSame,
                        image1Characteristics: this.form.get('image1')?.value,
                        image2Characteristics: this.form.get('image2')?.value,
                    }
                }
            )

            this.appSrv.setLoading(true)
            this.counter = await firstValueFrom(
                this.imagesSrv.tagger(this.images.id, tagger)
            )
            this.appSrv.setLoading(false)

            this.form.setValue({
                image1: {
                    blur: false,
                    dark: false,
                },
                image2: {
                    blur: false,
                    dark: false,
                }
            })
            this.loadImages().then()
        } catch (e: any) {
            const message = e.error?.message || e.message || "Ocurrio un error vuelva a intentarlo"

            this.appSrv.setLoading(false)
            this.snackBar.open(message)
        }
    }


}
