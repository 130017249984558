import {plainToInstance} from "class-transformer";
import {EvaluationTagger} from "./evaluation.tagger.model";

export class Tagger {
    requestId: string
    evaluation: EvaluationTagger

    static factory(payload: any): Tagger {
        return plainToInstance(Tagger, payload)
    }
}
