import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {Credentials} from "../../models/credentials.model";
import {Session} from "../../models/session.model";
import {Config} from "../../classes/config";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private http: HttpClient) {
    }


    public signIn(credentials: Credentials): Observable<Session> {
        return this.http.post(`${Config.api}/sign-in`, credentials)
            .pipe(
                map((response: any) => {
                    return Session.factory(response);
                })
            )
    }

}
