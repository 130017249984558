import {Component, OnInit} from '@angular/core';
import {IItemMenu} from "../../interfaces/item-menu.interface";
import {StorageService} from "../../services/storage/storage.service";
import {ToolsService} from "../../services/tools/tools.service";
import {SessionService} from "../../services/session/session.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {
    menuList: Array<IItemMenu> = []

    constructor(
        private storageSrv: StorageService,
        private sessionSrv: SessionService,
        private toolsSrv: ToolsService
    ) {
    }

    ngOnInit(): void {
        this.setupVars()
    }


    private setupVars(): void {
        this.menuList = [
            {
                label: "Comparaciones",
                path: "/dashboard/compare",
                icon: "compare_arrows"
            }
        ]
    }

    public signOut() {
        this.storageSrv.clear();
        this.sessionSrv.loadSession()
        this.toolsSrv.goToLogin().then()
    }
}
